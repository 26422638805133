const formatEventReserve = (extendedProps) => {
  // Determine the color based on amountDue
  const amountDue = parseFloat(extendedProps.amountDue.split(' ')[0]); // Extract numerical value
  const priceStyle = amountDue > 0 ? 'color:red;' : 'color:green;';
  const iconBookingType = extendedProps.bookingType === 'Solo' ? '<i class="pi pi-user"></i>' : '<i class="pi pi-user"></i><i class="pi pi-user"></i>';

  // เปลี่ยนจาก window.location.href เป็น window.open เพื่อเปิดในแท็บใหม่
  const redirectToInvoice = `window.open('/Invoice?BookingID=${extendedProps.BookingID}', '_blank')`;

  return `
    <p><i class="pi pi-clock"></i> Time: ${extendedProps.time} - <i class="pi pi-clock"></i> End time: ${extendedProps.endtime}\n</p>
    <p><i class="pi pi-user"></i> Cus. ${extendedProps.customerInfo}\n</p>
    <p><i class="pi pi-phone"></i> Phone: ${extendedProps.customerTel}\n</p>
    <p><i class="pi pi-calendar"></i> Date: ${extendedProps.date}\n</p>
    <p><i class="pi pi-briefcase"></i> Service: ${extendedProps.serviceDetail} ${iconBookingType} \n</p>
    <p><i class="pi pi-users"></i> Emp. ${extendedProps.employeeInfo}\n</p>
    <p><i class="pi pi-money-bill" style="${priceStyle}"></i> Price: ${extendedProps.price} </p>
    <p><i class="pi pi-money-bill" style="${priceStyle}"></i> Amount Paid: ${extendedProps.amountPaid} </p>
    <p><i class="pi pi-money-bill" style="${priceStyle}"></i> Amount Due : ${extendedProps.amountDue} </p>
    <p style="cursor: pointer;" onclick="${redirectToInvoice}"><i class="pi pi-circle-fill"></i> Reserva: ${extendedProps.BookingID} 
    <i class="pi pi-external-link"></i> </p>
    <p><i class="pi pi-circle-fill"></i> Reserva Detail: ${extendedProps.bookingDetailId} </p>
  `;
};

export default formatEventReserve;
