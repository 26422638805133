// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/DataQueues.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import '../css/DataQueues.scss';
import { Sidebar } from 'primereact/sidebar';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown'; // Import Dropdown

import EventDialog from '../components/EventDialog';
import fetchEvents from './DataQueues/fetchEvents';
import fetchResources from './DataQueues/fetchResources';
import EventContent from './DataQueues/EventContent';
import formatEventReserve from './DataQueues/formatEventReserve';
import ToastNotification from '../components/ToastNotification';
import { API_URLS } from '../api/api';

import { useSelector, useDispatch } from 'react-redux';

const useRefreshFlag = () => {
  const [refreshFlag, setRefreshFlag] = useState(false);
  const refresh = useCallback(() => {
    setRefreshFlag(prevFlag => !prevFlag);
  }, []);
  return [refreshFlag, refresh];
};

// New function to generate bookingStatus parameter
const getBookingStatusParam = () => {
  return '!=6';
};

import { getSelectedDate } from '../utils/dateUtils';

const DataQueues = ({ selectedDate, onDateChange, refreshFlag, setRefreshFlag, refreshData, EventDialogServiceTimeSidebar }) => {
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);

  const handleSubmitClickStatus = useSelector((state) => state.status.handleSubmitClickStatus);
  const dispatch = useDispatch();

  const [branches, setBranches] = useState([]); // Add state for branches
  const [selectedBranch, setSelectedBranch] = useState('1'); // Add state for selected branch with default value


  const [sidebarReserveVisible, setSidebarReserveVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [resources, setResources] = useState([]);
  const [interactionMode, setInteractionMode] = useState(true);
  const [eventDetails, setEventDetails] = useState({});
  const [showEventDialog, setShowEventDialog] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');

  const [localRefreshFlag, refreshLocalData] = useRefreshFlag();

  const handleShowToast = (variant, message) => {
    setToastVariant(variant);
    setToastMessage(message);
    setShowToast(true);
  };

  // Modify the fetchBranches function to add "Branch All"
  const fetchBranches = async () => {
    try {
      //const response = await fetch('https://py.testproject.work/branches/api/view', {
      const response = await fetch(API_URLS.BRANCHES, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      setBranches([{ branch_ID: 'all', branch_name: 'Branch All' }, ...data]);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };

  // Ensure this useEffect is calling the modified fetchBranches
  useEffect(() => {
    fetchBranches();
  }, []);


  useEffect(() => {
    const bookingStatusParam = getBookingStatusParam();
    fetchEvents(getSelectedDate(selectedDate), setEventDetails, handleShowToast, bookingStatusParam);
  }, [selectedDate, refreshFlag, localRefreshFlag]);

// Function to refresh events manually
const handleManualRefresh = async () => {
  const bookingStatusParam = getBookingStatusParam();
  await fetchEvents(getSelectedDate(selectedDate), setEventDetails, handleShowToast, bookingStatusParam);
  console.log('Calendar manually refreshed');
};

useEffect(() => {
  if (handleSubmitClickStatus === 1) {
    handleManualRefresh();
    // Reset the status after handling it
    dispatch({ type: 'SET_HANDLE_SUBMIT_CLICK_STATUS', payload: 0 });
  }
}, [handleSubmitClickStatus, dispatch]);
  useEffect(() => {
    fetchResources(setResources, selectedBranch);
  }, [selectedBranch]);

  useEffect(() => {
    const bookingStatusParam = getBookingStatusParam();
    fetchEvents(getSelectedDate(selectedDate), setEventDetails, handleShowToast, bookingStatusParam);
  }, [selectedDate, refreshFlag]);

  useEffect(() => {
    if (eventDetails[selectedDate]) {
      const eventsForSelectedDate = eventDetails[selectedDate].map((event, index) => ({
        id: `${selectedDate}_${index}`,
        resourceId: event.resourceId,
        title: `${event.service}`,
        start: `${selectedDate}T${event.startTime}`,
        end: `${selectedDate}T${event.endTime}`,
        extendedProps: event.extendedProps,
      }));

      setEvents(eventsForSelectedDate);
    }
  }, [eventDetails, selectedDate]);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.gotoDate(getSelectedDate(selectedDate));
    }
  }, [selectedDate]);

  const handleEventReserve = (eventInfo) => {
    const eventProps = eventInfo.event.extendedProps;
    const detailsForDisplay = formatEventReserve(eventProps);

    console.log('Extended Props:', eventProps); // Print extendedProps data
    setSelectedEvent({
      title: eventInfo.event.title,
      content: detailsForDisplay,
    });
    setSidebarReserveVisible(true);
  };

  const handleEventClick = (clickInfo) => {
    handleEventReserve(clickInfo);
  };

  const handleEventMouseEnter = (mouseEnterInfo) => {
    handleEventReserve(mouseEnterInfo);
  };

  const handleDateSelect = () => {
    setSidebarReserveVisible(false);
  };

  const closeSidebar = () => {
    setSidebarReserveVisible(false);
  };

  const eventDidMount = (info) => {
    const description = info.event.extendedProps.description || 'No description available';
    info.el.setAttribute('data-pr-tooltip', description);
  };

  const handleCustomButtonClick = () => {
    setShowEventDialog(true);
  };

  const branchValueTemplate = (option, props) => {
    if (option && option.branch_name) {
      return <span>{option.branch_name}</span>;
    }
    if (props && props.value) {
      const branch = branches.find(branch => branch.branch_ID === props.value);
      return branch ? <span>{branch.branch_name}</span> : <span>Select a Branch</span>;
    }
    return <span>Select a Branch</span>;
  };

  const branchOptionTemplate = (option) => {
    return <span>{option.branch_name}</span>;
  };



  return (
    <>
      <ToastNotification
        showToast={showToast}
        toastMessage={toastMessage}
        toastVariant={toastVariant}
        setShowToast={setShowToast}
      />

      <EventDialog
        show={showEventDialog}
        onHide={() => setShowEventDialog(false)}
        eventDetails={selectedEvent || {}}
      />

      <div className="custom-switch-container">
        <InputSwitch
          checked={interactionMode} onChange={(e) => setInteractionMode(e.value)}
        />
        <label className="custom-switch-label">Hover</label>
      </div>


      <div className="branch-dropdown-container" style={{ position: 'absolute', right: '128px', top: '100px' }}>
        <Dropdown
          value={selectedBranch}
          options={branches}
          onChange={(e) => setSelectedBranch(e.value)}
          optionLabel="branch_name"
          optionValue="branch_ID"
          placeholder="Select a Branch"
          valueTemplate={branchValueTemplate}
          itemTemplate={branchOptionTemplate}
        />
      </div>

      <Sidebar
        visible={sidebarReserveVisible}
        position="left"
        onHide={closeSidebar}
      >
        <p>{selectedEvent?.title}</p>
        <div dangerouslySetInnerHTML={{ __html: selectedEvent?.content }}></div>
      </Sidebar>

      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
        initialView="timeGridDay"
        events={events}
        eventDidMount={eventDidMount}
        editable={true}
        eventConstraint={{
          startTime: '09:00',
          endTime: '22:00',
        }}
        selectable={true}
        selectMirror={true}
        nowIndicator={true}
        eventClick={handleEventClick}
        dateClick={handleDateSelect}
        resources={resources}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }}
        allDaySlot={false}
        slotMinTime="09:00"
        slotMaxTime="22:00"
        customButtons={{
          myCustomButton: {
            text: 'Reserve+',
            click: () => handleCustomButtonClick()
          }
        }}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'myCustomButton, timeGridDay,timeGridWeek'
        }}
        titleFormat={{ year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }}
        initialDate={getSelectedDate(selectedDate)}
        datesSet={(dateInfo) => onDateChange(dateInfo.startStr)}
        eventMouseEnter={interactionMode ? handleEventMouseEnter : null}
        eventContent={(info) => <EventContent eventInfo={info} />}
      />



      <EventDialogServiceTimeSidebar
        visible={sidebarReserveVisible}
        onHide={() => setSidebarReserveVisible(false)}
        selectedDate={getSelectedDate(selectedDate)}
        onHideDialog={() => setShowEventDialog(false)}
        refreshParentData={refreshLocalData} // Pass the local refresh function
        handleManualRefresh={handleManualRefresh} // Pass handleManualRefresh as a prop
        />
    </>
  );
};

export default DataQueues;
