// /var/www/afra/dev.afaa.website/s-293-spatime-admin/src/components/DataQueues/EventDialogServiceTimeSidebar.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { Sidebar, InputSwitch, Button, InputText, ProgressSpinner } from 'primereact';
import { Row, Col } from 'react-bootstrap';
import '../../css/EventDialogServiceTimeSidebar.scss';
import bankInfo from '../../data/bankInfo.json'; // Adjust the path as needed

import PaymentDetails from '../../components/DataQueues/EventDialogServiceTimeSidebar/PaymentDetails';  // Import the new PaymentDetails 
import InputSwitchWrapper from '../../components/DataQueues/EventDialogServiceTimeSidebar/InputSwitchWrapper';  // Import the new 
import PaymentMethodContainer from '../../components/DataQueues/EventDialogServiceTimeSidebar/PaymentMethodContainer';
import PaymentLabels from '../../components/DataQueues/EventDialogServiceTimeSidebar/PaymentLabels';  // Import the new PaymentLabels component
import { API_URLS } from '../../api/api'; // นำเข้า API_URLS จาก api.js

const EventDialogServiceTimeSidebar = ({
  isSidebarVisible,
  onHide,
  selectedDate,
  handleSelectedBookingTime,
  maxServiceTimes,
  showToast,
  priceID,
  fetchReserveServiceTimes,
  cumulativePrice,
  setCumulativePrice,
  handleSubmit,
  hideServiceTypeSidebar,
  onHideDialog,
  refreshParentData,
  clearCumulativePrice
}) => {
  const dispatch = useDispatch();
  const [reserveServiceTimes, setReserveServiceTimes] = useState([]);
  const [selectedServiceTimesCount, setSelectedServiceTimesCount] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");
  const [topSidebarVisible, setTopSidebarVisible] = useState(false);

  const [paymentMethodGroup1, setPaymentMethodGroup1] = useState('payCash'); // Group 1: visa, payCash, qrCode
  const [paymentMethodGroup2, setPaymentMethodGroup2] = useState('store'); // Group 2: online, store


  const [paymentPercentage, setPaymentPercentage] = useState('full');
  const [paymentAmount, setPaymentAmount] = useState(cumulativePrice);
  const [loading, setLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true); // New state variable
  const [isRefreshing, setIsRefreshing] = useState(false); // New state variable

  const [paymentDetails, setPaymentDetails] = useState({ type: '', details: {} });
  const sidebarContentRef = useRef(null);

  // ปรับปรุงฟังก์ชัน handlePaymentPercentageChange
  const handlePaymentPercentageChange = (percentage) => {
    setPaymentPercentage(percentage);
    setPaymentAmount(percentage === 'half' ? cumulativePrice / 2 : (percentage === 'full' ? cumulativePrice : 0));
  };

  useEffect(() => {
    if (isSidebarVisible && shouldFetch) {
      setPaymentMethodGroup1('payCash'); // Set default payment method to Pay Cash
      loadPaymentDetails('payCash');
      setIsRefreshing(true); // Start the spinner
      fetchReserveServiceTimes(selectedDate, priceID, (times) => {
        //console.log("API Data:", times); // Print fetched data from API
        simulateAvailableTimes(times);  // Simulate the available times on sidebar visibility
        setIsRefreshing(false); // Stop the spinner
      });
      setSelectedServiceTimesCount(0); // Reset the count when the sidebar becomes visible
      setShouldFetch(false); // Prevent fetching again until needed
    }

    // Check sessionStorage data
    const storedTimes = JSON.parse(sessionStorage.getItem('tempReserveServiceTimes'));
    //console.log('Stored Reserve Service Times:', storedTimes);

  }, [isSidebarVisible, selectedDate, priceID, fetchReserveServiceTimes, shouldFetch]);


  useEffect(() => {
    if (topSidebarVisible) {
      setPaymentAmount(cumulativePrice);
      scrollToBottom(); // Scroll to bottom when the sidebar becomes visible
    }

    // Check sessionStorage data for consistency
    const storedTimes = JSON.parse(sessionStorage.getItem('tempReserveServiceTimes'));
    //console.log('Top Sidebar Visible - Stored Reserve Service Times:', storedTimes);

  }, [topSidebarVisible, cumulativePrice]);

  // Function to simulate available times based on API data
  const simulateAvailableTimes = (times) => {
    const simulatedTimes = times.map((slot) => {
      const isAvailable = slot.statusHH === "Available";
      return { ...slot, statusHH: isAvailable ? "Available" : "Unavailable", disabled: isAvailable };
    });
    setReserveServiceTimes(simulatedTimes);
  };

  // Function to count user-selected times
  const countUserSelectedTimes = (times) => {
    return times.filter(slot => slot.statusHH === "AvailableNow").length;
  };

  // Function to determine if a time slot should be checked
  const isTimeSlotChecked = (statusHH) => {
    return statusHH === "Available" || statusHH === "AvailableNow";
  };

  // Function to log the InputSwitch index
  const logInputSwitchIndex = (index) => {
    //console.log(`InputSwitch changed for index: ${index}`);
  };

  const toggleAvailability = (index) => {
    //console.log('Max Service Times:', maxServiceTimes); // Print maxServiceTimes
    //console.log('Selected Service Times Count:', selectedServiceTimesCount); // Print selectedServiceTimesCount

    setReserveServiceTimes(prevTimes => {
      const newTimes = [...prevTimes];
      const wasAvailableNow = newTimes[index].statusHH === "AvailableNow";

      // Toggle status between "AvailableNow" and "Unavailable"
      newTimes[index].statusHH = wasAvailableNow ? "Unavailable" : "AvailableNow";

      // Log the time that was toggled
      if (wasAvailableNow) {
        //console.log(`Time ${newTimes[index].time} switched to Unavailable`);
      } else {
        //console.log(`Time ${newTimes[index].time} switched to AvailableNow`);
      }

      // Recalculate the user-selected service times count
      const updatedSelectedServiceTimesCount = countUserSelectedTimes(newTimes);

      //console.log('Checking max service times:', updatedSelectedServiceTimesCount, '>', maxServiceTimes);

      // Check the maximum service times using the refactored function
      if (checkMaxServiceTimes(updatedSelectedServiceTimesCount)) {
        newTimes[index].statusHH = wasAvailableNow ? "AvailableNow" : "Unavailable"; // Revert the change
        return prevTimes;
      }

      setSelectedServiceTimesCount(updatedSelectedServiceTimesCount);

      // Update booking time to avoid setState in render
      setTimeout(() => {
        handleSelectedBookingTime(newTimes[index].time);
      }, 0);

      const status = newTimes[index].statusHH;
      setStatusMessage(`Status of ${newTimes[index].time} is ${status}`);

      // Print or log data when InputSwitch is clicked
      //console.log(`Toggled ${newTimes[index].time}: ${status}`);
      //console.log('Updated Selected Service Times Count:', updatedSelectedServiceTimesCount); // Print updated selectedServiceTimesCount

      // Store the updated reserveServiceTimes in sessionStorage
      sessionStorage.setItem('tempReserveServiceTimes', JSON.stringify(newTimes));
      setTopSidebarVisible(true);

      return newTimes;
    });
  };

  const checkMaxServiceTimes = (count) => {
    if (count > maxServiceTimes) {
      setTimeout(() => {
        showToast('warn', 'Limit Exceeded', `You can only select ${maxServiceTimes} service times.`);
      }, 0);
      return true;
    }
    return false;
  };

  // Corrected state reset to ensure only user-selected slots are reset
  const resetServiceTimeSelections = () => {
    const resetTimes = reserveServiceTimes.map(slot => ({
      ...slot,
      statusHH: slot.statusHH !== "Available" && !slot.disabled ? "Unavailable" : slot.statusHH
    }));
    setReserveServiceTimes(resetTimes);
    setSelectedServiceTimesCount(0);
    sessionStorage.setItem('tempReserveServiceTimes', JSON.stringify(resetTimes));
  };

  const handlePaymentMethodChangeGroup1 = (method) => {
    setPaymentMethodGroup1(method);
    setPaymentAmount(paymentPercentage === 'half' ? cumulativePrice / 2 : cumulativePrice);
    if (['payCash', 'qrCode'].includes(method)) loadPaymentDetails(method);
  };

  const handlePaymentMethodChangeGroup2 = (method) => {
    setPaymentMethodGroup2(method);
  };

  const loadPaymentDetails = (method) => {
    const details = method === 'payCash' ? bankInfo.payCash : bankInfo.qrCode;
    setPaymentDetails({ type: method, details });
  };

  const handlePaymentAmountChange = (value) => setPaymentAmount(value.replace(/\D/g, ''));
  const handleInputFocus = () => setPaymentAmount('');
  const handleInputBlur = (e) => { if (!e.target.value) setPaymentAmount(cumulativePrice); };

  const generateReferenceCode = () => {
    return [...Array(32)].map(() => Math.random().toString(36)[2]).join('');
  };



  const preparePaymentData = (referenceNumber, bookingID, customerID, employeeID, amount, remainingAmount, notes) => {
    const data = [
      {
        ReferenceNumber: referenceNumber,
        TransactionDate: new Date().toISOString(),
        BookingID: bookingID,
        ExpenseTypeID: 9,
        CustomerID: customerID,
        EmployeeID: employeeID,
        Amount: amount,
        Currency: "EUR",
        Remaining: remainingAmount,
        PaymentMethod: "5",
        PaymentStatus: "7",
        Status: "4",
        Notes: notes
      }
    ];
    console.log('Prepared Payment Data:', data); // Log prepared payment data
    return data;
  };

  const fetchLatestBookingData = async () => {
    try {
      //const response = await axios.get('https://py.testproject.work/booking/api/detail_calendarView', {
        const response = await axios.get(API_URLS.EVENTS, {
        params: {
          date_from: new Date().toISOString().split('T')[0],
          date_to: new Date().toISOString().split('T')[0]
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const bookingData = response.data;
      console.log('Fetched Latest Booking Data:', bookingData);

      // Transform and prepare the payment data for the most recent booking
      if (Array.isArray(bookingData) && bookingData.length > 0) {
        const latestBooking = bookingData[0];
        const referenceCode = generateReferenceCode();
        const notes = `${paymentMethodGroup2 === 'store' ? 'In-Store' : 'Online'}, ${paymentMethodGroup1 === 'payCash' ? 'Pay Cash' : paymentMethodGroup1 === 'visa' ? 'Visa' : 'QR-Code'}`;

        const remainingAmount = latestBooking.PriceValue - latestBooking.AmountPaid;

        const paymentData = preparePaymentData(
          referenceCode,
          latestBooking.BookingID,
          latestBooking.CustomerID,
          latestBooking.EmployeeID,
          latestBooking.PriceValue,
          remainingAmount,
          notes
        );
        console.log('Prepared Payment Data from latest booking:', paymentData);

        // Send payment data to the payment API
        await sendPaymentData(paymentData);
        // Send email notification
        await sendEmailNotification(latestBooking.BookingID, latestBooking.CustomerEmail);

        // Send SMS notification
        await sendSmsNotification(latestBooking.BookingID, latestBooking.CustomerTelephone);
      }

    } catch (error) {
      console.error('Error fetching latest booking data:', error);
    }
  };

  const sendEmailNotification = async (bookingID, customerEmail) => {
    try {
      const emailData = {
        order_id: bookingID,
        email: customerEmail
      };

      console.log('Sending Email Notification:', emailData); // Log email data for verification

      const response = await axios.post('https://py.testproject.work/send/api/email', emailData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('Email Notification API response:', response.data);
    } catch (error) {
      console.error('Error sending email notification:', error);
    }
  };

  const sendSmsNotification = async (bookingID, customerPhone) => {
    try {

      // ลบช่องว่างทั้งหมด
      customerPhone = customerPhone.replace(/\s+/g, '');
      // Ensure the phone number starts with a '+'
      if (!customerPhone.startsWith('+')) {
        customerPhone = `+33${customerPhone}`;
      }

      const smsData = {
        sms_recipients: [customerPhone],
        sms_text: `Thank you for your payment! OrderID ${bookingID} isaanspa`,
        sms_type: "SMS_WORLD",
        sms_sender: "Mirabeau Thai Spa"
      };

      console.log('Sending SMS Notification:', smsData); // Log SMS data for verification

      const response = await axios.post('https://py.testproject.work/sms/api/octopush', smsData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      console.log('SMS Notification API response:', response.data);
    } catch (error) {
      console.error('Error sending SMS notification:', error);
    }
  };



  const handleSubmitClick = async () => {
    setLoading(true);
    await handleSubmit(paymentAmount);
    setLoading(false);
    onHide();
    hideServiceTypeSidebar();
    setTopSidebarVisible(false);
    setShouldFetch(true);
    if (typeof onHideDialog === 'function') onHideDialog();
    if (typeof refreshParentData === 'function') refreshParentData();

    // Dispatch the action
    dispatch({ type: 'SET_HANDLE_SUBMIT_CLICK_STATUS', payload: 1 });

    // Fetch and process the latest booking details
    await fetchLatestBookingData();

  };



  const sendPaymentData = async (paymentData) => {
    try {
      console.log('Sending Payment Data:', paymentData); // Print the payment data for verification
      const response = await axios.post('https://py.testproject.work/payments/api/create', paymentData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Payment API response:', response.data);
    } catch (error) {
      console.error('Error sending payment data:', error);
    }
  };




  // Function to reset maxServiceTimes to 0
  const resetMaxServiceTimes = () => {
    setSelectedServiceTimesCount(0);
  };

  // Function to scroll sidebar content to the bottom
  const scrollToBottom = () => {
    if (sidebarContentRef.current) sidebarContentRef.current.scrollTop = sidebarContentRef.current.scrollHeight;
  };


  return (
    <>
      <Sidebar visible={isSidebarVisible} position="right" onHide={() => {
        onHide();
        resetServiceTimeSelections();
        setTopSidebarVisible(false);
        clearCumulativePrice();
        resetMaxServiceTimes();  // Reset maxServiceTimes when the sidebar is hidden        
        setShouldFetch(true); // Set shouldFetch to true after hiding
      }}>
        <h3>Service Time </h3>
        {isRefreshing ? <ProgressSpinner /> : (
          <div>
            {reserveServiceTimes.length > 0 && reserveServiceTimes.map((timeSlot, index) => (
              <div key={index} className="p-flex p-ai-center p-my-2">
                <span className="p-mr-2">{timeSlot.time}</span>
                <InputSwitch
                  checked={isTimeSlotChecked(timeSlot.statusHH)}
                  onChange={() => {
                    logInputSwitchIndex(index);  // Log the index when InputSwitch changes
                    toggleAvailability(index);
                  }}
                  disabled={timeSlot.statusHH === "Available" && timeSlot.disabled} // Disable the InputSwitch based on API data
                />
              </div>
            ))}
            {statusMessage && <div className="p-mt-3">{statusMessage}</div>}
          </div>
        )}
      </Sidebar>

      <Sidebar visible={topSidebarVisible} position="bottom" onHide={() => {
        setTopSidebarVisible(false);
        resetServiceTimeSelections();
        if (!paymentAmount) {
          setPaymentAmount(cumulativePrice);
        }
        resetMaxServiceTimes();  // Reset maxServiceTimes when the bottom sidebar is hidden
      }} className="custom-bottom-sidebar">

        <div className="sidebar-content" ref={sidebarContentRef}>
          <Row>
            <Col xs={4} className="cumulative-total-container text-center">


              <Row>

                <Col xs={3} className="cumulative-total-container text-center">
                <PaymentLabels /> 
                </Col>

                <Col xs={4} className="payment-method-container payment-InputSwitch">
                  <InputSwitchWrapper paymentMethodGroup1={paymentMethodGroup1} handlePaymentMethodChangeGroup1={handlePaymentMethodChangeGroup1} />
                </Col>

                <Col xs={4} className="credit-card-input-container">
                  <PaymentDetails paymentMethodGroup1={paymentMethodGroup1} paymentDetails={paymentDetails} />
                </Col>

              </Row>

            </Col>

            <Col xs={4} className="payment-method-container">
              <PaymentMethodContainer
                paymentMethodGroup2={paymentMethodGroup2}
                handlePaymentMethodChangeGroup2={handlePaymentMethodChangeGroup2}
                paymentAmount={paymentAmount}
                handlePaymentAmountChange={handlePaymentAmountChange}
                handleInputFocus={handleInputFocus}
                handleInputBlur={handleInputBlur}
                paymentPercentage={paymentPercentage}
                handlePaymentPercentageChange={handlePaymentPercentageChange}
              />
            </Col>

            <Col xs={4} className="d-flex align-items-center justify-content-end">
              {loading ? (
                <ProgressSpinner />
              ) : (
                <Button onClick={handleSubmitClick} className="full-submit-button">
                  <div className="confirm-button-content">
                    <div className="confirm-text">CONFIRM</div>
                    <div className="cumulative-total-text">CUMULATIVE TOTAL: ${cumulativePrice}</div>
                  </div>
                </Button>
              )}
            </Col>




          </Row>
        </div>
      </Sidebar>
    </>
  );
};

export default EventDialogServiceTimeSidebar;

