// /var/www/afra/dev.afaa.website/s-293-spatime-admin/src/pages/Invoice.js

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // เพิ่มเพื่อดึง query parameters
import Sidebar from './Sidebar';
import Header from '../components/Header';
import PropTypes from 'prop-types';
import '../css/InvoiceComponent.scss';
import { API_URLS, DATA_URLS } from '../api/api'; // ปรับให้ import เฉพาะ API_URLS เพราะ DATA_URLS ไม่ได้ใช้จริงในโค้ดนี้

const AdminInvoice = () => {
    const [language] = useState('EN');
    const [translations, setTranslations] = useState({});
    const [fixedRate, setFixedRate] = useState({});
    const [storeConstants, setStoreConstants] = useState({});
    const [customerDetails, setCustomerDetails] = useState({});
    const [bookingDetails, setBookingDetails] = useState({});
    const [bookingDetailsExtended, setBookingDetailsExtended] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);

    const [clientDetails, setClientDetails] = useState({
        name: 'Mirabeau thai spa',
        address: '10 RUE MIRABEAU 75016 PARIS',
        notes: 'IMPORTANT NOTES...',
    });

    const location = useLocation(); // ใช้ useLocation เพื่อดึง query parameters
    const t = translations[language] || {};

    useEffect(() => {
        const fetchConfigData = async () => {
            try {
                const translationsResp = await fetch(DATA_URLS.INVOICE_DATA_TRANSLATIONS);
                const translationsData = await translationsResp.json();
                setTranslations(translationsData);

                const fixedRateResp = await fetch(DATA_URLS.FIXED_RATE_CONN_API);
                const fixedRateData = await fixedRateResp.json();
                setFixedRate(fixedRateData);

                const storeConstantsResp = await fetch(DATA_URLS.STORE_CONSTANT);
                const storeConstantsData = await storeConstantsResp.json();
                setStoreConstants(storeConstantsData);
            } catch (error) {
                console.error("Fetching config data failed:", error);
            }
        };
        fetchConfigData();
    }, []);

    useEffect(() => {
        const fetchInvoices = async () => {
            // ดึง BookingID จาก query parameters หรือ fallback ไปที่ localStorage
            const queryParams = new URLSearchParams(location.search);
            const bookingIDFromURL = queryParams.get('BookingID');
            const bookingID = bookingIDFromURL || localStorage.getItem('localBookingReduxID');
            const token = localStorage.getItem('token');

            if (!bookingID) {
                console.error("No BookingID provided in URL or localStorage");
                return;
            }

            try {
                // Fetching booking details
                const bookingResponse = await fetch(API_URLS.BOOKING_VIEW(bookingID), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!bookingResponse.ok) {
                    throw new Error(`HTTP error! status: ${bookingResponse.status}`);
                }

                const bookingData = await bookingResponse.json();

                if (bookingData.length > 0) {
                    setBookingDetails(bookingData[0]);
                    const fetchedTotalAmount = parseFloat(bookingData[0].TotalAmount);
                    const fetchedAmountPaid = parseFloat(bookingData[0].AmountPaid);
                    setTotalAmount(fetchedTotalAmount);
                    setAmountPaid(fetchedAmountPaid);

                    // Fetching customer details
                    const customerID = bookingData[0].CustomerID;
                    const customerResponse = await fetch(API_URLS.USER_DETAILS(customerID), {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });

                    if (!customerResponse.ok) {
                        throw new Error(`HTTP error! status: ${customerResponse.status}`);
                    }

                    const customerData = await customerResponse.json();
                    setCustomerDetails(customerData);

                    // Fetching extended booking details
                    const detailResponse = await fetch(API_URLS.BOOKING_DETAIL_VIEW(bookingID));
                    if (!detailResponse.ok) {
                        throw new Error(`Failed to fetch booking details with status: ${detailResponse.status}`);
                    }
                    const bookingDetailsJson = await detailResponse.json();

                    const extendedDetails = await Promise.all(bookingDetailsJson.map(async (detail) => {
                        const priceResponse = await fetch(API_URLS.PRICE_SERVICE(detail.ServiceID));
                        if (!priceResponse.ok) {
                            throw new Error(`Failed to fetch price details for ServiceID ${detail.ServiceID}`);
                        }
                        const priceJson = await priceResponse.json();
                        return {
                            ...detail,
                            ServiceName: priceJson.results[0].ServiceName,
                            PriceType: priceJson.results[0].PriceType,
                            PriceValue: priceJson.results[0].PriceValue
                        };
                    }));

                    setBookingDetailsExtended(extendedDetails);

                    const tax = fetchedTotalAmount * (fixedRate.tax || 0) / 100;
                }
            } catch (error) {
                console.error("Fetching invoices failed:", error);
            }
        };

        fetchInvoices();
    }, [location.search]); // เพิ่ม location.search เป็น dependency เพื่อให้ทำงานเมื่อ query เปลี่ยน

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboard-main">
                <Header />
                <div className="content-area">
                    <div className="invoice-container">
                        <header className="invoice-header">
                            <img src="https://afaa.website/s/9ce743.png" alt="Company Logo" className="invoice-logo" />
                            <h1>{t['INVOICE']}</h1>
                        </header>
                        <section className="client-details">
                            <div className='text-left'>
                                <strong>{t['INVOICE_TO']}</strong>
                                <p>{customerDetails?.first_name} {customerDetails?.sur_name}</p>
                                <div className='line-index'></div>
                                <strong>{t['CONTACT_PERSON']}</strong>
                                <p className='bottom-unset'>{t['PHONE']}: {customerDetails?.telephone}</p>
                                <p className='bottom-unset'>{t['EMAIL']}: {customerDetails?.email}</p>
                                <p>{t['ADDRESS']}: {customerDetails?.contact}</p>
                            </div>
                            <div className='text-left'>
                                <p className='bottom-unset'>{t['INVOICE_NO']}: {bookingDetails?.BookingID}</p>
                                <p>{t['INVOICE_DATE']}: {formatDate(bookingDetails?.date)}</p>
                                <div className='line-index'></div>
                                <strong> {t['PAYMENT_METHOD']} </strong>
                                <p className='bottom-unset'>{t['ORDER_ID']}: {bookingDetails?.BookingID}</p>
                                <p className='bottom-unset'>{t['ACCOUNT_NAME']}: {customerDetails?.first_name} {customerDetails?.sur_name} </p>
                                <p> {t['STATUS']}: {bookingDetails?.PaymentStatusName}</p>
                            </div>
                        </section>
                        <table className="invoice-items">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t['DESCRIPTION']}</th>
                                    <th>{t['QUANTITY']}</th>
                                    <th>{t['SERVICE']}</th>
                                    <th>{t['PRICE']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingDetailsExtended.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.ServiceName || 'N/A'}</td>
                                        <td>{`${new Date(item.ServiceDate).toLocaleDateString()} ${item.ServiceTime}`}</td>
                                        <td>{item.PriceType || 'N/A'}</td>
                                        <td>{item.BookingDetailPrice ? `${item.BookingDetailPrice} €` : 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="row mt-4">
                            <div className="col-6">
                                <img src="https://afaa.website/s/9ce743.png" alt="Company Logo" className="footer-logo" />
                                <p className='bottom-unset'>{clientDetails.name}</p>
                                <p>{clientDetails.address}</p>
                                <div className='line-index'></div>
                                <p>{clientDetails.notes}</p>
                            </div>
                            <div className="col-3">
                                <p className="grid-item sub-total-label">{t['SUB_TOTAL']}</p>
                                <p className="grid-item sub-total-label">{t['TAX']} ({fixedRate.tax}%)</p>
                                <p className="grid-item sub-total-label">{t['TOTAL_TRA']} </p>
                            </div>
                            <div className="col-3">
                                <p className='grid-item'>{totalAmount.toFixed(2)}</p>
                                <p className='grid-item'>{((amountPaid * (fixedRate.tax || 0) / 100) || 0).toFixed(2)}</p>
                                <p className='grid-item'>{amountPaid.toFixed(2)}</p>
                            </div>
                        </div>
                        <div className='line-index'></div>
                        <div className='mt-2'>
                            <div className="legal-notes text-left">
                                <strong>{t['NOTES_IMPORTANTES']} :</strong>
                                <p>{t['NOTES_IMPORTANT']}</p>
                            </div>
                            <div className="row mt-4">
                                <div className="col-5">
                                    <p className="icon-text">
                                        <i className="pi pi-map-marker large-icon"></i>
                                        <span>{storeConstants.address}</span>
                                        <strong>{t['STORE_ADDRESS']}</strong>
                                    </p>
                                </div>
                                <div className="col-3">
                                    <p className="icon-text">
                                        <i className="pi pi-phone large-icon"></i>
                                        <span>{storeConstants.phone}</span>
                                    </p>
                                </div>
                                <div className="col-4">
                                    <p className="icon-text">
                                        <i className="pi pi-envelope large-icon"></i>
                                        <span>{storeConstants.email}</span>
                                    </p>
                                </div>
                            </div>
                            <div className='line-index'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AdminInvoice.propTypes = {
    language: PropTypes.string,
};

export default AdminInvoice;